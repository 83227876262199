class ZntCursor {
  constructor() {
    this.zntCursor = document.createElement('div');
    this.zntPointer = document.createElement('div');
    this.zntFollower = document.createElement('div');
    this.tapToView = document.createElement('div');
    this.clickAndDrag = document.createElement('div');
    this.currentX = null;
    this.currentY = null;
    this.pointerCircle = null;
    this.followerCircle = null;
    this.hasInitialized = false;

    const methods = Object.getOwnPropertyNames(Object.getPrototypeOf(this));
    methods
      .filter((method) => method !== 'constructor')
      .forEach((method) => {
        this[method] = this[method].bind(this);
      });
  }

  init() {
    this.zntCursor.id = 'cursor-wrapper';
    this.zntPointer.id = 'cursor-pointer';
    this.zntFollower.id = 'cursor-follower';
    this.tapToView.id = 'cursor-tap-to-view';
    this.clickAndDrag.id = 'cursor-images';
  }

  start() {
    function isTouchDevice() {
      return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
    }

    if (window.innerWidth > 1024) {
      if (!isTouchDevice()) {
        if (!this.hasInitialized) {
          this.addCursor();

          this.currentX = window.innerWidth / 2;
          this.currentY = window.innerHeight / 2;

          this.pointerCircle = {
            lastX: this.currentX,
            lastY: this.currentY,
            size: this.zntPointer.getBoundingClientRect(),
          };

          this.followerCircle = {
            lastX: this.currentX,
            lastY: this.currentY,
            size: this.zntFollower.getBoundingClientRect(),
          };
        }

        this.hasInitialized = true;

        this.addEvents();
        this.render();
      }
    }
  }

  update() {
    console.log('update');
  }

  destroy() {
    this.zntCursor.classList.remove('active');
    this.zntCursor.classList.remove('hover-team');
    this.zntCursor.classList.remove('hover-images');
  }

  addCursor() {
    this.zntCursor.appendChild(this.zntPointer);
    this.zntCursor.appendChild(this.zntFollower);
    this.zntPointer.appendChild(this.tapToView);
    this.zntPointer.appendChild(this.clickAndDrag);
    document.body.appendChild(this.zntCursor);
  }

  removeCursor() {
    this.zntCursor.remove();
  }

  updatePosition(x, y) {
    this.currentX = x;
    this.currentY = y;
  }

  lerp(from, to, ease) {
    return (1 - ease) * from + ease * to;
  }

  circleMovement(circle, ease, direction) {
    if (direction === 'x') {
      return this.lerp(circle.lastX, this.currentX, ease);
    }

    return this.lerp(circle.lastY, this.currentY, ease);
  }

  render() {
    this.pointerCircle.lastX = this.circleMovement(this.pointerCircle, 1, 'x');
    this.pointerCircle.lastY = this.circleMovement(this.pointerCircle, 1, 'y');
    this.zntPointer.style.transform = `translate(${this.pointerCircle.lastX - this.pointerCircle.size.width / 2}px, ${
      this.pointerCircle.lastY - this.pointerCircle.size.height / 2
    }px)`;

    this.followerCircle.lastX = this.circleMovement(this.followerCircle, 0.3, 'x');
    this.followerCircle.lastY = this.circleMovement(this.followerCircle, 0.3, 'y');
    this.zntFollower.style.transform = `translate(${
      this.followerCircle.lastX - this.followerCircle.size.width / 2
    }px, ${this.followerCircle.lastY - this.followerCircle.size.height / 2}px)`;
    requestAnimationFrame(this.render);
  }

  addEvents() {
    window.addEventListener('pointermove', (e) => this.updatePosition(e.clientX, e.clientY));

    document.body.addEventListener('mouseenter', this.showCursor);
    document.body.addEventListener('mouseleave', this.hideCursor);

    document.querySelectorAll('a').forEach((link) => {
      link.addEventListener('mouseover', this.linkEnter);
      link.addEventListener('mouseout', this.linkLeave);
    });

    document.querySelectorAll('.member').forEach((member) => {
      member.addEventListener('mouseover', this.teamEnter);
      member.addEventListener('mouseout', this.teamLeave);
    });

    document.querySelectorAll('section.images .swiper').forEach((section) => {
      section.addEventListener('mouseover', this.imagesSlideEnter);
      section.addEventListener('mouseout', this.imagesSlideLeave);
      section.addEventListener('mousedown', this.imagesSlideClickEnter);
      section.addEventListener('mouseup', this.imagesSlideClickLeave);
    });
  }

  removeEvents() {
    window.removeEventListener('pointermove', (e) => this.updatePosition(e.clientX, e.clientY));

    document.body.removeEventListener('mouseenter', this.showCursor);
    document.body.removeEventListener('mouseleave', this.hideCursor);

    document.querySelectorAll('a').forEach((link) => {
      link.removeEventListener('mouseover', this.linkEnter);
      link.removeEventListener('mouseout', this.linkLeave);
    });

    document.querySelectorAll('.member').forEach((member) => {
      member.removeEventListener('mouseover', this.teamEnter);
      member.removeEventListener('mouseout', this.teamLeave);
    });

    document.querySelectorAll('section.images .swiper').forEach((section) => {
      section.removeEventListener('mouseover', this.imagesSlideEnter);
      section.removeEventListener('mouseout', this.imagesSlideLeave);
      section.removeEventListener('mousedown', this.imagesSlideClickEnter);
      section.removeEventListener('mouseup', this.imagesSlideClickLeave);
    });
  }

  showCursor() {
    this.zntCursor.style.display = 'block';
  }

  hideCursor() {
    this.zntCursor.style.display = 'none';
  }

  linkEnter() {
    this.zntCursor.classList.add('active');
  }
  linkLeave() {
    this.zntCursor.classList.remove('active');
  }
  teamEnter() {
    this.zntCursor.classList.add('hover-team');
  }
  teamLeave() {
    this.zntCursor.classList.remove('hover-team');
  }
  imagesSlideEnter() {
    this.zntCursor.classList.add('hover-images');
  }
  imagesSlideLeave() {
    this.zntCursor.classList.remove('hover-images');
  }
  imagesSlideClickEnter() {
    this.zntCursor.classList.add('drag');
  }
  imagesSlideClickLeave() {
    this.zntCursor.classList.remove('drag');
  }
}

export default ZntCursor;
